/* eslint-disable react-hooks/exhaustive-deps */
import '../src/App.scss';
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from "react-router-dom";
import React, {lazy, useEffect} from 'react';
import {RoleEnum, SaleAccessLevels} from './platform/enums/role';
import {useDispatch, useSelector} from 'react-redux';
import {selectUser} from './redux/user/user.selector';
import {selectTheme} from './redux/theme/theme.selector';
import WelcomePage from './pages/welcomePage/WelcomePage';
import {createGlobalStyle} from 'styled-components';
import {loadGoogleMapScript} from './components/shared/loadScripts/loadScripts';
import API from './platform/api/api';
import * as Actions from './redux'
import ChangePasswordPage from "./pages/ChangePasswordPage/ChangePasswordPage";
import {LoginRoutes, PublicRoutes} from "./platform/enums/Routes";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import SignUpPage from "./pages/signUpPage/SignUpPage";
import {Theme, ThemeColors} from "./platform/enums/theme";

// Sections
const SalesHome = lazy(() => import('./pages/Profiles/SalesHomePage/SalesHome'));
const ReceptionistHomePage = lazy(() => import('./pages/Profiles/Receptionist/ReceptionistHomePage'));
const TenantHome = lazy(() => import('./pages/Profiles/Tenant/TenantHome'));
const BuildHomePage = lazy(() => import('./pages/Profiles/BuildHomePage/BuildHomePage'));
const ManagersHomePage = lazy(() => import('./pages/Profiles/Manager/ManagersHomePage'));

// public routes
const StocklistPublic = lazy(() => import('./components/StockListPublic/StockListPublic'));
const PlanInfo = lazy(() => import('./components/shared/PlanInfo/PlanInfo'));
const NetworkError = lazy(() => import('./components/shared/NetworkError/NetworkError'));

const GlobalStyle = createGlobalStyle<any>`

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-track { 
    background: rgba(${props => props.theme.rgb }, 0.3);
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${props => props.theme.hex };
  }
`

export const invalidPhoneNoError : string = "Error: In valid phone number"
function IsAustralianTelephoneNumberValid(number : string | null) {
    debugger;
    let count: number = 0;
    if(number){
        if(number.charAt(0) ==='0'){
            count = 1;
        }
        if(number.charAt(0) ==='6' && number.charAt(1) ==='1'){
            count = 2;
        }
        if(number.charAt(0) ==='+' && number.charAt(1) ==='6' && number.charAt(2) ==='1'){
            count = 3;
        }
        number = number.replace(/\s/g, '');
        let length_with_digits = number.length;
        number = number.replace(/0|1|2|3|4|5|6|7|8|9/g,'');
        if((number === '') && (length_with_digits-number.length >= (8 + count))) {
            return true;
        }
        else {
            return false;
        }
    } else {
        return false;
    }
}
export const validatePhoneNumber = (val: any, multi: boolean = false) => {
    debugger;
    if(multi){
        let result = true;
        for(let i = 0; i < val?.length ; i++){
            if(result){
                if(!IsAustralianTelephoneNumberValid(val[i]?.toString() ?? null)){
                    result = false
                }
            } else {
                break;
            }
        }
        return result
    } else {
        return IsAustralianTelephoneNumberValid(val?.toString() ?? null);
    }
}
function App() {
  const user = useSelector(selectUser);
  const theme = useSelector(selectTheme);

  const history = useHistory();
  const dispatch = useDispatch();

  const getAuthToken = () => {
    return localStorage.getItem('authToken');
  }
  const authCheck = () => {

    let token = getAuthToken();
    if (token != null) {

      // if login within same UI session set user
      API.interceptors.request.use(
        async config => {
          config.headers = {
            'Authorization': `Bearer ${getAuthToken()}`
          }
          return config;
        },
        error => {
          Promise.reject(error)
        });

      API.interceptors.response.use((response) => {
        return response;
      }, (error) => {

        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('user');
            localStorage.removeItem('constants');
            dispatch(Actions.setUser(null))
            history.push(PublicRoutes.home);
            // setAuthToken(null);
          }
        } else {
          setTimeout(() => {
            // debugger;
            // history.push(PublicRoutes.networkerror);
          }, 1000);
        }
        return Promise.reject(error);
      });
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    loadGoogleMapScript();
    if(authCheck()){
      const userLocal:any = JSON.parse(localStorage.getItem('user') as any);
      dispatch(Actions.setUser(userLocal))
      
    } else {
    }
  },[])
    const userSwitcher = (role: string, access: string) => {
        let switchUser = {...user};
        switchUser.role = role;
        switchUser.access = access;
        dispatch(Actions.setUser(switchUser))
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(switchUser));
        const userTheme = Theme[Object.keys(Theme).find((i) => Theme[i as keyof ThemeColors]['role'] === switchUser.role) as keyof ThemeColors];
        dispatch(Actions.setTheme(userTheme))
    }
  const privateRoute = () => {
    return (
      <Route exact path="/*">
        {authCheck() && user != null && user.role === RoleEnum.Sales &&
          <>
            { user.access === SaleAccessLevels.receptionist ?
                <React.Suspense fallback={<div />}>
                    <ReceptionistHomePage switcher={userSwitcher} />
                </React.Suspense>

                :
                <React.Suspense fallback={<div />}>
                    <SalesHome switcher={userSwitcher} />
                </React.Suspense>
            }
          </>
        }
        {authCheck() && user != null && user.role === RoleEnum.Tenant &&
            <React.Suspense fallback={<div />}>
                <TenantHome switcher={userSwitcher} />
            </React.Suspense>
        }
        {authCheck() && user != null && user.role === RoleEnum.Build &&
            <React.Suspense fallback={<div />}>
                <BuildHomePage switcher={userSwitcher} />
            </React.Suspense>
        }
        {authCheck() && user != null && user.role === RoleEnum.Management &&
            <React.Suspense fallback={<div />}>
                <ManagersHomePage switcher={userSwitcher} />
            </React.Suspense>
        }
        {!authCheck() &&
          <Redirect to={PublicRoutes.home} />
        }
      </Route>
    )
  }

  const loginRoutes = () => {
    return (
      !authCheck() ?
        <Switch>
          <Route exact path={PublicRoutes.home}>
            <WelcomePage />
          </Route>
          <Route exact path={LoginRoutes.signup}>
           <SignUpPage/>
          </Route>
          <Route exact path={LoginRoutes.resetpassword}>
            <ResetPasswordPage />
          </Route>
          <Route exact path={LoginRoutes.changepassword}>
            <ChangePasswordPage />
          </Route>
          <Route path="*">
            <Redirect to={PublicRoutes.home} />
          </Route>
        </Switch>
          :
          <Redirect to={LoginRoutes.defaultPage} />
    )
  }

  return (
    <Router>
      <GlobalStyle theme={theme} />
      <div className="App d-flex">
        <Switch>
          <Route exact path={PublicRoutes.live}
             render={() => (
                 <React.Suspense fallback={<div />}>
                     <PlanInfo/>
                 </React.Suspense>
             )}
          />
          <Route exact path={PublicRoutes.stocks}
          render={() => (
                  <React.Suspense fallback={<div />}>
                      <StocklistPublic/>
                  </React.Suspense>
              )}
          />
          <Route exact path={PublicRoutes.networkerror}
             render={() => (
                 <React.Suspense fallback={<div />}>
                     <NetworkError/>
                 </React.Suspense>
             )}
          />
          {authCheck() && privateRoute()}
          {!authCheck() && loginRoutes()}
        </Switch>
      </div>
    </Router>
  );
}

export default App;


