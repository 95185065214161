/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ButtonPR from '../../components/shared/Button/Buttons';
import './SignUpPage.scss';
import axios from "axios";
import {baseURL} from "../../platform/api/api";
import Select from "react-select";
import $ from "jquery";
import {PublicRoutes} from "../../platform/enums/Routes";
import {RoleEnum} from "../../platform/enums/role";

const BGCircle = styled.div<any>`
    position: fixed;
    width: 1264px;
    height: 1264px;
    // z-index: -11;
    z-index: 0;
    background: ${props => props.bg};
    border-radius: 1023.84px;
    left: calc(50% - 1264px/2 - 327px);
    top: calc(50% - 1264px/2);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: url('/static/icons/ralph.svg');
    background-size: 230px;
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 499px;
    background-position-y: 374px;
    background-attachment: fixed;
    /* transition: all ease-in-out 0.1s; */

    &::before{
        content: '';
        width: 300px;
        height: 100%;
        position: absolute;
        top: 407px;
        left: 38%;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(${props => props.roleImage});
        /* transition: all ease-in-out 0.1s; */

        
    }
    &::after{
            content: '${props => props.imageTitle}';
            position: relative;
            display: block;
            top: 884px;
            left: 0%;
            text-align: center;
            font-weight: 600;
            font-size: 40px;
            line-height: 105%;
            /* identical to box height, or 42px */

            text-align: center;
            letter-spacing: -1px;

            /* White */

            color: #FFFFFF;
            
        }
`;
const Text = styled.span<any>`
    color: ${props => props.color};
    margin: 0 ${props => props.right ? "19px" : false} 0 ${props => props.left ? "19px" : false};
`

const ErrorText = styled.div`
    color: #ff4646;
`;

const SuccessText = styled.div<any>`
    color: ${props => props.color};
`;

const Loader = styled.div<any>`
    border-top-color: ${props => props.color};
    margin: 0 auto;
`;

const SuccessWrapper = styled.div`
    position: relative;
    padding: 100px 0;
    top: 50%;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 52.8378px;
    line-height: 105%;
    /* or 55px */
    max-width: 634.05px;
    letter-spacing: -1.32095px;
    margin-bottom: 40px;
    /* White */

    color: #FFFFFF;

    `;

const PageDescription = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 22.4561px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    color: #FFFFFF;
    max-width: 446.48px;
`;

const Button = styled.button<any>`
    background: ${props => props.bg};
    border-radius: 12px;
    padding: 19px 33%;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    border: none;
    text-align: center;
    outline: none;
    color: #fff;
`;
const Logo = styled.img`
    margin-left: 66px;
`;

const LogoWrapper = styled.div`
    position: fixed;
    top: 75px;
    left: 81px;     
`;

const Container = styled.div`
        display: block;
        width:100%;
`;

const SelectComp = styled.div<any>`
    margin-top: 20px;
    padding: 0px 20px;
    margin-bottom: 31px;
`;
const Dropify = styled.div<any>`
.dropify-wrapper {
    border: 2.5px dashed #F9BA6A !important;
    border-radius: 50%;
    color: #ADB5BD;
    width: 130px !important;
    height: 130px !important;
}
.dropify-wrapper .dropify-message p {
    font-size: 13px;
    color: #F9BA6A;
    font-weight: bold;
}
.dropify-wrapper .dropify-message .file-icon {
    color: #F9BA6A !important;
}
.dropify-preview {
    background-color: inherit !important;
}`;
const DropifyContainer = styled.div<any>`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
`;
const SignUpPage: React.FC = (props: any) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole]: any = useState(null);
    const [formError, setFormError] = useState(false);
    const [image, setImage]: any = useState("");
    const [loading, setLoading] = useState(false);
    const [countDown, setCountDown]: any = useState(null);
    const [signupSuccess, setSignupSuccess]: any = useState(false);
    const [successMessage, setSuccessMessage]: any = useState("");
    const [errorMessage, setErrorMessage]: any = useState("");
    const roleOptions = [
        {id: 1, name: RoleEnum.Sales},
        {id: 2, name: RoleEnum.Build},
        {id: 3, name: RoleEnum.Tenant},
        {id: 4, name: RoleEnum.Management}
    ];
    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            color: "#F9BA6A",
            background: "#FFFFFF",
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            border: state.isFocused && "1px solid #F9BA6A",
            '&:hover': {
                border: '1px solid #F9BA6A',
            },
            boxShadow: "none",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            color: "#F9BA6A",
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            background: state.isSelected && 'grey',
            '&:hover': {
                background: "#F2F2F2",
            },
        }),
    }
    useEffect(() => {
        $(".dropify").dropify({
            messages: {
                'default': 'Upload profile image here',
                'replace': 'Drag and drop or click to replace',
                'remove':  'Remove',
                'error':   'Oops, something wrong happened'
            }
        });
    }, []);

    const history = useHistory();
    const getFormatData = () => {
        return {
            email: email,
            password: password,
            name: name,
            role_id: role?.id,
            image: image
        }
    }

    const signup = () => {
        const endPoint = axios.create({
            baseURL: baseURL,
        });
        if(email === "" || password === "" || name === "" || role === null){
            alert("All fields are required");
            return false;
        }
        if(password.length < 7) {
            alert("Password must be of minimum 7 characters");
            return false;
        }
        if(password !== confirmPassword){
            alert("Passwords dont match");
            return false;
        }
        let data = getFormatData();
        const formData = new FormData();
        // eslint-disable-next-line array-callback-return
        Object.keys(data).map((key:any, index : number) => {formData.append(key, Object.values(data)[index])});
        setFormError(false);
        setLoading(true);
        endPoint.post('user/sign-up', formData,{headers: {'content-type': 'multipart/form-data'}} )
            .then(res => {
                setSignupSuccess(true);
                setSuccessMessage(res.data);
                setFormError(false);
                setLoading(false);
                setCountDown(10);

            }).catch(error => {
                setErrorMessage(error?.response?.data?.message);
                setSignupSuccess(false);
                setFormError(true);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (countDown === 0) {
            setCountDown(null);
            history.push(PublicRoutes.home);
        }
        if (signupSuccess === false || !countDown) return;
        const intervalId = setInterval(() => {
            setCountDown(countDown - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [countDown]);

    return (
        <>
            <div className="signup-page-wrapper">
                <Container>
                    <LogoWrapper className="left-top-box">
                        <span className="button-left">
                            <ButtonPR bg="#fff" onClick={() => history.push(PublicRoutes.home)} type={"circle"} icon={<svg width="8px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path fill="#F9BA6A"
                                    d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                                />
                            </svg>}/>
                            <Text color="#fff" right={true}>Go Back</Text>
                        </span>
                        <Logo src="/static/icons/logo-new.svg" className="signuplogo" />
                    </LogoWrapper>

                    <div className="top-actions">
                        <span className="button-right">
                            <ButtonPR bg="#F9BA6A" onClick={props.onClose} type={"circle"} size="small" icon={<svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.07247 11.0312C3.57768 11.0312 3.98723 10.6216 3.98723 10.1164C3.98723 9.61121 3.57768 9.20166 3.07247 9.20166C2.56727 9.20166 2.15771 9.61121 2.15771 10.1164C2.15771 10.6216 2.56727 11.0312 3.07247 11.0312Z" fill="#fff" />
                                <path d="M3.07273 0.96875C1.45866 0.96875 0.145508 2.2819 0.145508 3.89597C0.145508 4.30015 0.473137 4.62777 0.877313 4.62777C1.28149 4.62777 1.60912 4.30015 1.60912 3.89597C1.60912 3.08894 2.26569 2.43236 3.07273 2.43236C3.87976 2.43236 4.53634 3.08894 4.53634 3.89597C4.53634 4.703 3.87976 5.35958 3.07273 5.35958C2.66855 5.35958 2.34092 5.68721 2.34092 6.09138V7.9209C2.34092 8.32507 2.66855 8.6527 3.07273 8.6527C3.4769 8.6527 3.80453 8.32507 3.80453 7.9209V6.73058C5.06565 6.40482 5.99995 5.25746 5.99995 3.89597C5.99995 2.2819 4.6868 0.96875 3.07273 0.96875Z" fill="white" />
                            </svg>}/>
                        </span>
                    </div>

                    <BGCircle bg="#F9BA6A" />
                    <div className="row content-wrapper">
                        <div className="col-md-6">
                            <div className="page-info-wrapper">
                                <Title>Ask the admin to approve.</Title>
                                <PageDescription>Once you sign up here, the admin of this software will have to approve the request for you to sign up successfully.</PageDescription>
                                <PageDescription>This is for one time only, after this you can login anytime using your credentials.</PageDescription>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="round-wrapper-login">
                                <div className="">

                                    {signupSuccess && !loading &&
                                        <SuccessWrapper className="mb-4">
                                            <SuccessText color="#F9BA6A">{successMessage} - {countDown} </SuccessText>
                                        </SuccessWrapper>
                                    }
                                </div>
                                {!signupSuccess &&
                                    <div className="container">
                                        <div className="form-group">
                                            <form onSubmit={(e: any) => e.preventDefault()} autoComplete="off">
                                                <DropifyContainer>
                                                    <Dropify>
                                                        <input onChange={(e: any) => setImage(e.target.files[0])} type="file" className="dropify" data-allowed-file-extensions="png psd jpg jpeg" />
                                                    </Dropify>
                                                </DropifyContainer>
                                                <div className="input-wrapper">
                                                    <label htmlFor="name">
                                                        Name
                                                    </label>
                                                    <input value={name} type="text" onChange={(e: any) => setName(e.target.value)} />
                                                </div>
                                                <div className="input-wrapper">
                                                    <label htmlFor="email">
                                                        Email Address
                                                    </label>
                                                    <input value={email} type="email" onChange={(e: any) => setEmail(e.target.value)} />
                                                </div>
                                                <div className="input-wrapper">
                                                    <label htmlFor="password">
                                                        Password
                                                    </label>
                                                    <input value={password} type="password" onChange={(e: any) => setPassword(e.target.value)} />
                                                </div>
                                                <div className="input-wrapper">
                                                    <label htmlFor="conformpassword">
                                                        Confirm Password
                                                    </label>
                                                    <input value={confirmPassword} type="password" onChange={(e: any) => setConfirmPassword(e.target.value)} />
                                                </div>
                                                <div className="input-wrapper">
                                                    <label htmlFor="Role">
                                                        Role/Department
                                                    </label>
                                                    <SelectComp>
                                                        <Select
                                                            getOptionLabel={ (x:any) => x.name}
                                                            getOptionValue={ (x:any) => x.name}
                                                            options={roleOptions}
                                                            onChange={(sel: any)=> {
                                                                setRole(sel);
                                                            }}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            styles={customStyles}
                                                            placeholder={<div>Select role</div>}
                                                        />
                                                    </SelectComp>
                                                </div>
                                            </form>
                                            <Button onClick={signup} bg={'#F9BA6A'} className="">Continue</Button>
                                            {formError &&
                                                <div className="mt-4 mb-4">
                                                    <ErrorText>{errorMessage}</ErrorText>
                                                </div>
                                            }
                                            {loading &&
                                                <div className="mt-4 mb-4 text-center">
                                                    <Loader color="#F9BA6A" className="min-loader">

                                                    </Loader>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                </Container>
            </div>
        </>
    );
}

export default SignUpPage;