import styled from "styled-components";

export interface WelcomeButtonProps {
    bg: string;
    bgsec: string;
    title: string;
    imageSrc: string;
    classes: string;
    buttonClick?: () => void;
}
const LoginBadge = styled.div<any>`
/* display:inline-block; */
    max-width: 330px;
    width: 330px;
    height: 122.02px;
    border-radius: 81px;
    background: linear-gradient(180deg, ${ props => props.bg } 0%, ${props => props.bgsec} 100%);
    /* top: -85px; */
    /* position: relative; */
    padding:1px;
    margin-bottom:42px;
    position: relative;
    left:0;
    cursor: pointer;
    transition: all 0.3s;
    &:hover{
        left: 45px;
        transition: all 0.3s;
    }
`;
const LoginBadgeCircle = styled.div`
background: #FFFFFF;
box-shadow: 0px 8px 23px rgba(77,76,76,0.06);
width: 82px;
height: 82px;
border-radius: 50%;
margin-top: 16px;
margin-bottom: 23px;
padding: 21px 21px;
margin-left: 20px;
margin-right: 20px;
display: inline-block;
`;

const LoginBadgeText = styled.span`
font-size: 22px;
line-height: 27px;
color: rgba(255, 255, 255, 0.9);
`;

const LoginBadgeIcon = styled.img`

`;
const WelcomeButton: React.FC<WelcomeButtonProps> = (props) => {
    return (
        <>
            <LoginBadge className={props.classes} bg={props.bg} bgsec={props.bgsec} title={props.title} onClick={props.buttonClick}>
                <LoginBadgeCircle>
                    <LoginBadgeIcon width="40px" src={props.imageSrc} />
                </LoginBadgeCircle>
                <LoginBadgeText>{props.title}</LoginBadgeText>
            </LoginBadge>
        </>
    );
}

export default WelcomeButton;