import { combineReducers } from "@reduxjs/toolkit";
import userReducer from './user/user.slice';
import themeReducer from './theme/theme.slice';
import filterSelectionsReducer from './BuildsSection/buildsListing.slice'

export const rootReducer = combineReducers({
    userReducer,
    themeReducer,
    filterSelectionsReducer
});
